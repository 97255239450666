import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import Footer from "@sections/Footer";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Section, Container } from "@components/global";

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  margin-bottom: 2rem;
`;

const HeaderH1 = styled.h1`
  margin-top: 0.5rem;
  text-align: center;
`;

const HeaderH2 = styled.h2`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 22px;
  text-align: left;
`;

const PostCard = styled.article`
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  border-radius: 4px;

  margin-top: 0.5rem;
  font-size: 26px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100%;
  }
  div {
    & p {
      text-align: left;
    }
  }

  @media (min-width: 750px) {
    flex-wrap: nowrap;
    img {
      width: 33%;
      margin-right: 15px;
    }
  }
`;

const Posts = ({ data }) => {
  const list = data.allPrismicPost.edges.map((e) => e.node);
  return (
    <Layout>
      <TopHeader />

      <Section fluid>
        <HeaderH1 style={{ marginBottom: "1.5rem" }}>Aktualności</HeaderH1>
        <StandaloneText>
          {list.map((post) => (
            <PostCard key={post.uid}>
              <img src={post.data.image.url} />
              <div>
                <HeaderH2>{post.data.title.text}</HeaderH2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.data.content.html.substring(0, 400) + "...",
                  }}
                />
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    href={"/" + post.uid}
                  >
                    Czytaj dalej
                  </Button>
                </div>
              </div>
            </PostCard>
          ))}
        </StandaloneText>
      </Section>
      <Footer />
    </Layout>
  );
};
export default Posts;
export const pageQuery = graphql`
  query PostsBySlug {
    allPrismicPost {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            image {
              url
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`;
